<template>
  <div class="page page--about">
    <section class="section" id="sec-1" data-sec="1">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="img img--about-sec1">
              <img src="/img/pages/about/sec1/img-1.png" alt="About">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="wrapper d-flex flex-column justify-content-center ps-xl-4 pe-xl-5">
              <p class="text tc-yellow fs-xl-20 fw-500 ta-center ta-md-left mb-3">{{ cc.sec1.text[0] }}</p>
              <h1 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center ta-md-left mb-3 mb-md-4"
                  :class="[{'pe-xl-5': ($i18n.locale !== 'ru' && $i18n.locale !== 'cs')}]">
                {{ cc.sec1.title }}
              </h1>
              <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center ta-md-left mb-4 mb-md-5 px-3 px-sm-0">{{ cc.sec1.text[1] }}</p>
              <div class="d-flex justify-content-center justify-content-md-start">
                <a :href="cc.sec1.link.to" v-smooth-scroll="{ duration: 1000, offset: -100 }" class="link link--arrow link--td-none">
                  <span class="link fs-16 fs-md-18 fs-xl-20 tc-blue">
                    {{ cc.sec1.link.text }}
                  </span>
                  <i class="ico ico--ui-arrow">
                    <img src="/img/icons/ui/ico-any-arrow-right-blue.svg" alt="arrow">
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-2" data-sec="2">
      <div class="container">
        <div class="row flex-column-reverse flex-md-row">
          <div class="col-12 col-md-6 col-lg-6 col-xl-5 offset-lg-1 offset-xl-2">
            <div class="wrapper d-flex align-items-center">
              <p class="text fs-14 fs-sm-20 fw-400 lh-20 ta-center ta-md-left">
                <span class="tc-silver">{{ cc.sec2.text[0] }}</span> <span class="tc-gray">{{ cc.sec2.text[1] }}</span> <span class="tc-silver">{{ cc.sec2.text[2] }}</span> <span class="tc-gray">{{ cc.sec2.text[3] }}</span> <span class="tc-silver">{{ cc.sec2.text[4] }}</span> <span class="tc-gray">{{ cc.sec2.text[5] }}</span>
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <div class="img img--about-sec2">
              <img src="/img/pages/about/sec2/img-3.svg" alt="virarosi">
              <img src="/img/pages/about/sec2/img-2.svg" alt="virarosi">
              <img src="/img/pages/about/sec2/img-1.svg" alt="virarosi">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-3" data-sec="3">
      <div class="container">
        <div class="row mb-5 pb-md-5">
          <div class="col-12 col-md-6 mb-sm-4 mb-md-0" v-for="(item, i) in cc.sec3.boxes" :key="'what-we-do-' + i">
            <div class="wrapper d-flex px-lg-4">
              <div class="ico ico--circle me-3" :class="'ico--circle-' + item.theme">
                <img :src="'/img/icons/ico-' + item.key + '-' + item.theme + '.svg'" :alt="item.key">
              </div>
              <div class="wrapper">
                <p class="text fs-14 fs-sm-16 fs-md-20 fw-600 mb-3">{{ item.title }}</p>
                <p class="text tc-silver fs-14 fs-md-16 lh-20">{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="block block--shadowed">
              <div class="row">
                <div class="col-12 col-lg-10 offset-lg-1 px-4 py-4 py-md-5">
                  <div class="row mb-3 mb-md-5">
                    <div class="col-12">
                      <h2 class="title fs-28 fs-xl-32 fw-600 lh-13 ta-center">{{ cc.sec3.title }}</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4" v-for="(item, i) in cc.sec3.values" :key="'what-we-do-' + i">
                      <div class="wrapper d-flex flex-column justify-content-center align-items-center">
                        <div class="ico ico--circle mb-3" :class="'ico--circle-' + item.theme">
                          <img :src="'/img/icons/ico-' + item.key + '-' + item.theme + '.svg'" :alt="item.key" />
                        </div>
                        <div class="wrapper">
                          <p class="text fs-14 fs-sm-16 fs-md-20 fw-600 ta-center">{{ item.title }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-4" data-sec="4">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12">
            <p class="text tc-peach fs-18 fs-md-20 fw-500 ta-center">{{ cc.sec4.title }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="text tc-silver fs-14 fs-md-15 fw-400 lh-20 mb-4 mb-md-5">{{ cc.sec4.text[0] }}</p>
            <p class="text tc-silver fs-14 fs-md-15 fw-400 lh-20">{{ cc.sec4.text[1] }}</p>
            <p class="text tc-silver fs-14 fs-md-15 fw-400 lh-20" v-for="(item, i) in cc.sec4.list" :key="'legal-list-' + i">
              <span class="tc-peach">({{ getListIndex(i) }})</span> <span class="tc-silver">{{ item }}</span>
            </p>
          </div>
          <div class="col-12 col-lg-6">
            <p class="text tc-silver fs-14 fs-md-15 fw-400 lh-20 mb-4 mb-md-5">{{ cc.sec4.text[2] }}</p>
            <p class="text tc-silver fs-14 fs-md-15 fw-400 lh-20">{{ cc.sec4.text[3] }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--gray" id="sec-5" data-sec="5">
      <comp-contacts/>
    </section>
  </div>
</template>
<script>
import CompContacts from '@/components/Contacts'
export default {
  name: 'About',
  components: {
    CompContacts
  },
  computed: {
    cc () {
      return this.$t('pages.about')
    }
  },
  methods: {
    getListIndex (n) {
      let count = 0
      let pattern = ''
      while (count <= n) {
        pattern += 'i'
        count++
      }
      return pattern
    }
  },
  metaInfo () {
    const meta = this.$t('meta.about')
    return {
      titleTemplate: '%s | ' + meta.name,
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
